import React, { useState, useEffect } from 'react';
import { Typography, Tooltip } from '@mui/material';
import { useTranslation } from './LocalizationProvider';

const CopyableText = ({ text }) => {
  const [copied, setCopied] = useState(false);
  const t = useTranslation();

  const handleCopy = () => {
    if (text) {
      navigator.clipboard.writeText(text);
      setCopied(true);
    }
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 500);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [copied]);

  return (
    <Tooltip title={t('sharedCopyToClipboard')} placement="top">
      <Typography
        variant="inherit"
        component="span"
        style={{
          cursor: 'pointer',
          textDecoration: 'underline dotted',
          color: '#1a73e8',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          backgroundColor: copied ? '#e8f0fe' : 'transparent',
          transition: 'background-color 0.3s ease',
          borderRadius: '2px',
        }}
        onClick={handleCopy}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

export default CopyableText;
