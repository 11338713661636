import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import {
  useMediaQuery,
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Menu,
  MenuItem,
  CardMedia,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import MapIcon from '@mui/icons-material/Map';
import AddGeofenceIcon from '@mui/icons-material/AddLocation';
import EditGeofencesIcon from '@mui/icons-material/EditLocationAlt';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';
import SendIcon from '@mui/icons-material/Send';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import StopCircleIcon from '@mui/icons-material/PauseCircleFilled';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import BuildIcon from '@mui/icons-material/Build';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ImpoundLotIcon from '@mui/icons-material/CarCrash';
import { useTheme } from '@mui/material/styles';

import { useTranslation } from './LocalizationProvider';
import RemoveTerminateDialog from './RemoveTerminateDialog';
import { useAdministrator, useDeviceReadonly, useRestriction } from '../util/permissions';
import { devicesActions } from '../../store';
import { getUserCommandId } from './CommonValues';
import {
  callJsonAPI,
  postJsonAPI,
  putJsonAPI,
  deleteJsonAPI,
  handleErrorResponse,
  fetchMsgPack,
} from '../util/utils';
import MaintenanceDialog from './MaintenanceDialog';
import SpeedLimitDialog from './SpeedLimitDialog';
import GeofenceDialog from './GeofenceDialog';
import GeofenceListDialog from './GeofenceListDialog';
import NotificationsDialog from './NotificationsDialog';
import SettingsDialog from './SettingsDialog';
import TransferDialog from './TransferDialog';
import RecoveryDialog from './RecoveryDialog';
import PropsDialog from './PropsDialog';
import AIAnalysisDialog from './AIAnalysisDialog';
import TabPanel from './StatusTabPanel';
import { ActionIcon } from './ActionObject';
import { MessageDisplay } from './CommonObjects';
import LotInfoDialog from './LotInfoDialog';
import { MapMenuItem } from './MapElement';
import CopyableText from './CopyableText';

import { ReactComponent as RecoveryIcon } from '../../resources/images/data/recovery.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.up('lg')]: {
      width: theme.dimensions.popupMaxWidthLargeScreen,
    },
    [theme.breakpoints.down('lg')]: {
      width: theme.dimensions.popupMaxWidthSmallScreen,
    },
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  headerRight: {
    marginLeft: 'auto',
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  root: ({ desktopPadding }) => ({
    position: 'fixed',
    zIndex: 5,
    left: '50%',
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${desktopPadding} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(3)} + ${theme.dimensions.bottomBarHeight}px)`,
    },
    transform: 'translateX(-50%)',
  }),
}));

const StatusCard = ({ deviceId, position, onClose, disableActions, desktopPadding = 0 }) => {
  const classes = useStyles({ desktopPadding });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const theme = useTheme();

  const admin = useAdministrator();
  const deviceReadonly = useDeviceReadonly() || useRestriction('readonly');

  const device = useSelector((state) => state.devices.items[deviceId]);
  const userId = useSelector((state) => state.session.user.id);
  const userEmail = useSelector((state) => state.session.user.email);
  const deviceImage = device?.attributes?.deviceImage;
  const group = useSelector((state) => state.groups.items[device.groupId]);

  const [anchorMap, setAnchorMap] = useState(null);
  const [anchorMore, setAnchorMore] = useState(null);
  const [anchorAlerts, setAnchorAlerts] = useState(null);
  const [anchorActions, setAnchorActions] = useState(null);
  const [anchorDevice, setAnchorDevice] = useState(null);

  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [opentTerminateDialog, setOpentTerminateDialog] = useState(false);
  const [opentResetDialog, setOpentResetDialog] = useState(false);

  const [openRecoveryDialog, setOpenRecoveryDialog] = React.useState(false);
  const [openSettingsDialog, setOpenSettingsDialog] = React.useState(false);
  const [openMaintenanceDialog, setOpenMaintenanceDialog] = React.useState(false);
  const [openSpeedLimitDialog, setOpenSpeedLimitDialog] = React.useState(false);
  const [openGeofenceDialog, setOpenGeofenceDialog] = React.useState(false);
  const [openGeofenceListDialog, setOpenGeofenceListDialog] = React.useState(false);
  const [openTransferDialog, setOpenTransferDialog] = React.useState(false);
  const [openNotificationsDialog, setOpenNotificationsDialog] = React.useState(false);
  const [openPropsDialog, setOpenPropsDialog] = React.useState(false);
  const [openAnalysisDialog, setOpenAnalysisDialog] = React.useState(false);
  const [openLotInfoDialog, setOpenLotInfoDialog] = React.useState(false);
  const [actionResult, setActionResult] = useState();

  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const handleRefresh = async () => {
    const devices = await fetchMsgPack('/api/devices'); // Use the utility function
    dispatch(devicesActions.refresh(devices)); // Dispatch the decoded data
  };

  const handleRemove = async (device) => {
    setOpenRemoveDialog(false);
    if (device) {
      deleteJsonAPI(`devices/${device.id}`, null, false, null, setActionResult);
      onClose();
      handleRefresh();
    }
  };

  const handleTerminate = async (device) => {
    if (device) {
      const terminatedDevice = { ...device, disabled: true };
      putJsonAPI(`devices/${device.id}`, terminatedDevice, true, null, setActionResult);
      handleRefresh();
    }
    setOpentTerminateDialog(false);
  };

  const handleReset = async (device) => {
    if (device) {
      // Call the reset API and get the new device
      const response = await postJsonAPI(`devices/${device.id}/reset`, {}, false, null);
      const newDevice = JSON.parse(response);

      if (newDevice) {
        // remove the old device
        dispatch(devicesActions.remove(device.id));

        // add the new device (make sure it's in an array)
        dispatch(devicesActions.update([newDevice]));

        // select the new device
        dispatch(devicesActions.select(newDevice.id));
        setActionResult(t('sharedReset'));
      }
    }
    setOpentResetDialog(false);
  };

  const handleTransferClose = async (item) => {
    setOpenTransferDialog(false);
    if (item) {
      await postJsonAPI('orders', { uniqueId: Date.now(), description: 'Device Transfer', fromAddress: userEmail, toAddress: item.email, attributes: { deviceId, notify: item.notify } }, false, 'Device Transferred', setActionResult);
      handleRefresh();
      onClose();
    }
  };

  const handleCommand = async (name) => {
    const id = await getUserCommandId(name, userId);
    if (id) {
      await postJsonAPI('commands/send', { id, deviceId }, false, t('commandSent'), setActionResult);
    }
  };

  const handleSettingsClose = async (item) => {
    setOpenSettingsDialog(false);
    if (item) {
      handleCommand(item.name);
    }
  };

  const handleSpeedLimitClose = async (item) => {
    setOpenSpeedLimitDialog(false);
    if (item) {
      handleCommand(item.speed);
    }
  };

  const createPermissionBody = (keyBase, baseId, keyLink, linkId) => {
    const body = {};
    body[keyBase] = baseId;
    body[keyLink] = linkId;
    return body;
  };

  const handleAnalysisClose = async () => {
    setOpenAnalysisDialog(false);
  };

  const handlePropsClose = async () => {
    setOpenPropsDialog(false);
  };

  const handleImpoundLotClose = async () => {
    setOpenLotInfoDialog(false);
  };

  const handleRecoveryClose = async (items) => {
    setOpenRecoveryDialog(false);
    if (items) {
      const results = [];
      items.forEach((item) => {
        if (item.newAssigned !== item.origAssigned) {
          const method = item.newAssigned ? 'POST' : 'DELETE';
          const body = JSON.stringify(createPermissionBody('userId', item.id, 'deviceId', deviceId));
          results.push(fetch('/api/permissions', {
            method,
            headers: { 'Content-Type': 'application/json' },
            body,
          }));
        }
      });
      if (results.length > 0) {
        await Promise.all(results);
        // handleRefresh();
        setActionResult(t('sharedAssigned'));
      }
    }
  };

  const handleMaintenanceClose = async (item) => {
    setOpenMaintenanceDialog(false);
    if (item) {
      const maintenance = await postJsonAPI('maintenance', item, true, null);
      if (maintenance) {
        await postJsonAPI('permissions', { deviceId, maintenanceId: maintenance.id }, false, 'Maintenance Record Added', setActionResult);
      }
    }
  };

  const createNotification = async (notification, deviceId, setActionResult) => {
    try {
      const body = { type: notification.type, geofenceId: notification.geofenceId, notificators: 'mail,sms,web', attributes: {}, always: false, calendarId: 0, commandId: 0 };
      const newNotification = await postJsonAPI('notifications', body, true, null);
      if (newNotification) {
        await postJsonAPI('permissions', { deviceId, notificationId: newNotification.id }, false, null, setActionResult);
      }
    } catch (error) {
      await handleErrorResponse(error);
    }
  };

  const deleteNotification = async (notificationId) => {
    try {
      await callJsonAPI(`notifications/${notificationId}`, 'DELETE', {}, false, null);
    } catch (error) {
      await handleErrorResponse(error);
    }
  };

  const handleNotificationsClose = async (notifications) => {
    setOpenNotificationsDialog(false);
    if (notifications) {
      notifications.forEach(async (notification) => {
        if (notification.newAssigned !== notification.origAssigned) {
          if (notification.newAssigned) {
            await createNotification(notification, deviceId, setActionResult);
          } else {
            await deleteNotification(notification.id);
          }
        }
      });
      // handleRefresh();
    }
  };

  const getNotificationObject = (type, geofenceId) => ({
    type,
    geofenceId,
  });

  const placeGeofence = async (latitude, longitude, name, radius) => {
    const geofenceItem = {
      name: `${name}`,
      area: `CIRCLE (${latitude} ${longitude}, ${radius})`,
    };
    const geofence = await postJsonAPI('geofences', geofenceItem, true, null, setActionResult);
    if (geofence) {
      await postJsonAPI('permissions', { deviceId, geofenceId: geofence.id }, false, t('sharedGeofenceCreated'), setActionResult);
      await createNotification(getNotificationObject('geofenceExit', geofence.id), deviceId, null);
      await createNotification(getNotificationObject('geofenceEnter', geofence.id), deviceId, null);
    }
  };

  const handleGeofence = async (item) => {
    if (item.address !== '') {
      const query = new URLSearchParams({ address: item.address });
      const response = await fetch(`/api/server/geodecode?${query.toString()}`);
      if (response.ok) {
        const location = await response.json();
        placeGeofence(location.latitude, location.longitude, item.name, item.radius);
      } else {
        await handleErrorResponse(response);
      }
    } else {
      placeGeofence(position.latitude, position.longitude, item.name, item.radius);
    }
  };

  const handleGeofenceClose = (item) => {
    setOpenGeofenceDialog(false);
    if (item) {
      handleGeofence(item);
    }
  };

  const handleGeofenceListClose = async (items) => {
    setOpenGeofenceListDialog(false);
    if (items) {
      const results = [];
      items.forEach((item) => {
        if (item.toDelete === true) {
          results.push(fetch(`/api/geofences/${item.id}`, { method: 'DELETE' }));
        }
      });
      if (results.length > 0) {
        await Promise.all(results);
        // handleRefresh();
        setActionResult(t('sharedGeofencesRemoved'));
      }
    }
  };

  const retVal = (
    <div className={classes.root}>
      {device && (
        <Draggable
          handle={`.${classes.media}, .${classes.header}`}
        >
          <Card elevation={3} className={classes.card}>
            {deviceImage ? (
              <CardMedia
                className={classes.media}
                image={`/api/media/${device.uniqueId}/${deviceImage}`}
              >
                <IconButton
                  size="small"
                  onClick={onClose}
                  onTouchStart={onClose}
                >
                  <CloseIcon fontSize="small" className={classes.mediaButton} />
                </IconButton>
              </CardMedia>
            ) : (
              <div className={classes.header}>
                <div className={classes.headerLeft}>
                  <CopyableText text={device.name} />
                  {admin && (
                    <Typography
                      color="textSecondary"
                      style={{ marginLeft: '8px' }}
                    >
                      {group.name}
                    </Typography>
                  )}
                </div>
                <div className={classes.headerRight}>
                  <IconButton
                    size="small"
                    onClick={onClose}
                    onTouchStart={onClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            )}
            <CardContent className={classes.content}>
              <TabPanel
                largeScreen={largeScreen}
                device={device}
                position={position}
              />
            </CardContent>
            <CardActions disableSpacing>
              <ActionIcon
                text={t('sharedMaps')}
                color="secondary"
                action={(e) => setAnchorMap(e.currentTarget)}
                icon={(<MapIcon />)}
                disabled={!position}
                largeScreen={largeScreen}
              />
              {!largeScreen && (
                <ActionIcon
                  text={t('sharedShowDetails')}
                  color="secondary"
                  action={(e) => setAnchorMore(e.currentTarget)}
                  icon={(<OfflineBoltIcon />)}
                  largeScreen={largeScreen}
                />
              )}
              {!deviceReadonly && (
                <ActionIcon
                  text={t('sharedAlerts')}
                  color={device.impoundTime != null ? 'negative' : 'secondary'}
                  action={(e) => setAnchorAlerts(e.currentTarget)}
                  icon={(<CircleNotificationsIcon />)}
                  largeScreen={largeScreen}
                />
              )}
              {largeScreen && (
                <>
                  {admin && (
                    <ActionIcon
                      text={t('sharedActions')}
                      color="secondary"
                      action={(e) => setAnchorActions(e.currentTarget)}
                      icon={(<BuildIcon />)}
                      largeScreen={largeScreen}
                    />
                  )}
                  {position && (
                    <ActionIcon
                      text={t('sharedInfoTitle')}
                      color="secondary"
                      action={() => { setOpenPropsDialog(true); }}
                      icon={(<TroubleshootIcon />)}
                      largeScreen={largeScreen}
                    />
                  )}
                  <ActionIcon
                    text={t('reportAIAnalysis')}
                    color="secondary"
                    action={() => { setOpenAnalysisDialog(true); }}
                    icon={(<FaceRetouchingNaturalIcon />)}
                    largeScreen={largeScreen}
                  />
                  <ActionIcon
                    text={t('reportRecentStops')}
                    color="secondary"
                    action={() => { navigate('/reports/stop?autoRun=true'); }}
                    icon={(<StopCircleIcon />)}
                    largeScreen={largeScreen}
                  />
                  <ActionIcon
                    text={t('commandRefresh')}
                    color="secondary"
                    action={() => { handleCommand(t('commandRefresh')); }}
                    icon={(<MyLocationIcon />)}
                    largeScreen={largeScreen}
                  />
                </>
              )}
              {!deviceReadonly && largeScreen && (
                <ActionIcon
                  text={t('sharedRecovery')}
                  color={device.recovery ? 'negative' : 'secondary'}
                  action={() => { setOpenRecoveryDialog(true); }}
                  icon={(<RecoveryIcon />)}
                  disabled={deviceReadonly}
                  largeScreen={largeScreen}
                />
              )}
              {deviceReadonly && (
                <ActionIcon
                  text={t('sharedView')}
                  color="primary"
                  action={() => { navigate(`/settings/device/${deviceId}`); }}
                  icon={(<ViewIcon />)}
                  largeScreen={largeScreen}
                />
              )}
              {!deviceReadonly && (
                <ActionIcon
                  text={t('sharedDevice')}
                  color="primary"
                  action={(e) => setAnchorDevice(e.currentTarget)}
                  icon={(<SmartphoneIcon />)}
                  largeScreen={largeScreen}
                />
              )}
              <MessageDisplay message={actionResult} setMessage={setActionResult} severity="success" />
            </CardActions>
          </Card>
        </Draggable>
      )}
      {position && (
        <Menu
          anchorEl={anchorMap}
          open={Boolean(anchorMap)}
          onClose={() => setAnchorMap(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {(admin && (
            <MenuItem onClick={() => navigate(`/position/${position.id}`)}>
              <ListItemText>{t('sharedShowDetails')}</ListItemText>
            </MenuItem>
          ))}
          <MapMenuItem latitude={position.latitude} longitude={position.longitude} type="google" />
          <MapMenuItem latitude={position.latitude} longitude={position.longitude} type="bing" />
          <MapMenuItem latitude={position.latitude} longitude={position.longitude} type="street" />
        </Menu>
      )}
      <Menu
        anchorEl={anchorDevice}
        open={Boolean(anchorDevice)}
        onClose={() => setAnchorDevice(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItem onClick={() => navigate(`/settings/device/${deviceId}`)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t('sharedEdit')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setOpenTransferDialog(true)}>
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText>{t('sharedTransfer')}</ListItemText>
        </MenuItem>
        {!device.disabled && (
          <MenuItem onClick={() => setOpentTerminateDialog(true)}>
            <ListItemIcon className={classes.negative}>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText className={classes.negative}>{t('sharedTerminate')}</ListItemText>
          </MenuItem>
        )}
        {!device.disabled && (
          <MenuItem onClick={() => setOpentResetDialog(true)}>
            <ListItemIcon className={classes.negative}>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText className={classes.negative}>{t('sharedReset')}</ListItemText>
          </MenuItem>
        )}
        {admin && (
          <MenuItem onClick={() => setOpenRemoveDialog(true)}>
            <ListItemIcon className={classes.negative}>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText className={classes.negative}>{t('sharedRemove')}</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <Menu
        anchorEl={anchorActions}
        open={Boolean(anchorActions)}
        onClose={() => setAnchorActions(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItem onClick={() => setOpenSettingsDialog(true)}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText>{t('deviceCommand')}</ListItemText>
        </MenuItem>
        {position && (
          <MenuItem onClick={() => setOpenSpeedLimitDialog(true)}>
            <ListItemIcon>
              <SpeedIcon />
            </ListItemIcon>
            <ListItemText>{t('attributeSpeedLimit')}</ListItemText>
          </MenuItem>
        )}
        {position && (
          <MenuItem onClick={() => setOpenMaintenanceDialog(true)}>
            <ListItemIcon>
              <ConstructionOutlinedIcon />
            </ListItemIcon>
            <ListItemText>{t('sharedMaintenance')}</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <Menu
        anchorEl={anchorAlerts}
        open={Boolean(anchorAlerts)}
        onClose={() => setAnchorAlerts(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {device.impoundTime != null && (
          <MenuItem onClick={() => setOpenLotInfoDialog(true)}>
            <ListItemIcon className={classes.negative}>
              <ImpoundLotIcon />
            </ListItemIcon>
            <ListItemText className={classes.negative}>{t('sharedImpoundLot')}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => setOpenNotificationsDialog(true)}>
          <ListItemIcon>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText>{t('sharedNotifications')}</ListItemText>
        </MenuItem>
        {position && (
          <MenuItem onClick={() => setOpenGeofenceDialog(true)}>
            <ListItemIcon>
              <AddGeofenceIcon />
            </ListItemIcon>
            <ListItemText>{t('sharedCreateGeofence')}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => setOpenGeofenceListDialog(true)}>
          <ListItemIcon>
            <EditGeofencesIcon />
          </ListItemIcon>
          <ListItemText>{t('sharedGeofences')}</ListItemText>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorMore}
        open={Boolean(anchorMore)}
        onClose={() => setAnchorMore(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {position && (
          <MenuItem onClick={() => setOpenPropsDialog(true)}>
            <ListItemIcon>
              <TroubleshootIcon />
            </ListItemIcon>
            <ListItemText>{t('sharedInfoTitle')}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => setOpenAnalysisDialog(true)}>
          <ListItemIcon>
            <FaceRetouchingNaturalIcon />
          </ListItemIcon>
          <ListItemText>{t('reportAIAnalysis')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate('/reports/stop?autoRun=true')}>
          <ListItemIcon>
            <StopCircleIcon />
          </ListItemIcon>
          <ListItemText>{t('reportRecentStops')}</ListItemText>
        </MenuItem>
        {!deviceReadonly && (
          <MenuItem onClick={() => setOpenRecoveryDialog(true)}>
            <ListItemIcon>
              <RecoveryIcon />
            </ListItemIcon>
            <ListItemText>{t('sharedRecovery')}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => handleCommand(t('commandRefresh'))}>
          <ListItemIcon>
            <MyLocationIcon />
          </ListItemIcon>
          <ListItemText>{t('commandRefresh')}</ListItemText>
        </MenuItem>
      </Menu>
      {openRemoveDialog && (
        <RemoveTerminateDialog
          title={t('sharedRemoveConfirm')}
          open={openRemoveDialog}
          device={device}
          handleClose={handleRemove}
        />
      )}
      {opentTerminateDialog && (
        <RemoveTerminateDialog
          title={t('sharedTerminateConfirm')}
          open={opentTerminateDialog}
          device={device}
          handleClose={handleTerminate}
        />
      )}
      {opentResetDialog && (
        <RemoveTerminateDialog
          title={t('sharedResetConfirm')}
          open={opentResetDialog}
          device={device}
          handleClose={handleReset}
        />
      )}
      {openGeofenceDialog && (
        <GeofenceDialog
          open={openGeofenceDialog}
          handleClose={handleGeofenceClose}
          disabled={disableActions}
        />
      )}
      {openGeofenceListDialog && (
        <GeofenceListDialog
          userId={userId}
          deviceId={deviceId}
          open={openGeofenceListDialog}
          handleClose={handleGeofenceListClose}
          disabled={disableActions}
        />
      )}
      {openNotificationsDialog && (
        <NotificationsDialog
          open={openNotificationsDialog}
          handleClose={handleNotificationsClose}
          deviceId={deviceId}
          disabled={disableActions}
        />
      )}
      {openSpeedLimitDialog && (
        <SpeedLimitDialog
          open={openSpeedLimitDialog}
          handleClose={handleSpeedLimitClose}
          disabled={disableActions}
        />
      )}
      {openMaintenanceDialog && (
        <MaintenanceDialog
          open={openMaintenanceDialog}
          handleClose={handleMaintenanceClose}
          disabled={disableActions}
        />
      )}
      {openTransferDialog && (
        <TransferDialog
          open={openTransferDialog}
          handleClose={handleTransferClose}
          disabled={disableActions}
        />
      )}
      {openSettingsDialog && (
        <SettingsDialog
          open={openSettingsDialog}
          handleClose={handleSettingsClose}
          disabled={disableActions}
        />
      )}
      {openRecoveryDialog && (
        <RecoveryDialog
          deviceId={deviceId}
          open={openRecoveryDialog}
          handleClose={handleRecoveryClose}
          disabled={disableActions}
        />
      )}
      {openPropsDialog && (
        <PropsDialog
          device={device}
          position={position}
          open={openPropsDialog}
          handleClose={handlePropsClose}
        />
      )}
      {openAnalysisDialog && (
        <AIAnalysisDialog
          deviceId={deviceId}
          from={device.installTime}
          to={device.lastUpdate}
          open={openAnalysisDialog}
          handleClose={handleAnalysisClose}
        />
      )}
      {openLotInfoDialog && (
        <LotInfoDialog
          deviceId={deviceId}
          open={openLotInfoDialog}
          handleClose={handleImpoundLotClose}
        />
      )}
    </div>
  );
  return retVal;
};

export default StatusCard;
