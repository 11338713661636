import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Paper, BottomNavigation, BottomNavigationAction, Menu, MenuItem, Typography, Badge,
} from '@mui/material';

import DescriptionIcon from '@mui/icons-material/Description';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BuildIcon from '@mui/icons-material/Build'; // Importing new icon

import { sessionActions } from '../../store';
import { useTranslation } from './LocalizationProvider';
import { useAdministrator, useRestriction, useRecovery } from '../util/permissions';
import { nativePostMessage } from './NativeInterface';

const useStyles = makeStyles((theme) => ({
  enabled: {
    color: theme.palette.secondary.main,
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
}));

const BottomMenu = ({ filteredDevices = [] }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const t = useTranslation();

  const readonly = useRestriction('readonly');
  const recovery = useRecovery();
  const admin = useAdministrator();
  const disableReports = useRestriction('disableReports');
  const user = useSelector((state) => state.session.user);
  const socket = useSelector((state) => state.session.socket);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);

  const [toolsAnchorEl, setToolsAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  const currentSelection = () => {
    if (location.pathname === `/settings/user/${user.id}`) {
      return 'account';
    } if (location.pathname.startsWith('/settings')) {
      return 'settings';
    } if (location.pathname.startsWith('/reports')) {
      return 'reports';
    } if (location.pathname === '/') {
      return 'home';
    }
    return null;
  };

  const handleSettings = () => {
    setProfileAnchorEl(null);
    navigate(`/settings/user/${user.id}`);
  };

  const handleLogout = async () => {
    setProfileAnchorEl(null);

    const notificationToken = window.localStorage.getItem('notificationToken');
    if (notificationToken) {
      window.localStorage.removeItem('notificationToken');
      const tokens = user.attributes.notificationTokens?.split(',') || [];
      if (tokens.includes(notificationToken)) {
        const updatedUser = {
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens: tokens.length > 1 ? tokens.filter((it) => it !== notificationToken).join(',') : undefined,
          },
        };
        await fetch(`/api/users/${user.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedUser),
        });
      }
    }

    await fetch('/api/session', { method: 'DELETE' });
    nativePostMessage('logout');
    navigate('/login');
    dispatch(sessionActions.updateUser(null));
  };

  const handleSelection = (event, value) => {
    switch (value) {
      case 'home':
        navigate('/');
        break;
      case 'reports':
        navigate('/reports/stop');
        break;
      case 'tools':
        setToolsAnchorEl(event.currentTarget); // Open Tools menu
        break;
      case 'account':
        setProfileAnchorEl(event.currentTarget); // Open Profile menu
        break;
      case 'logout':
        handleLogout();
        break;
      case 'tools/testDevice':
        setToolsAnchorEl(null); // Close Tools menu
        navigate('/install');
        break;
      case 'tools/findServices':
        setToolsAnchorEl(null); // Close Tools menu
        navigate('/tools/find-recovery');
        break;
      case 'tools/copyDeviceIds': {
        setToolsAnchorEl(null); // Close Tools menu
        // Copy device IDs to clipboard
        const deviceIds = filteredDevices.map((device) => device.name).join('\n');
        navigator.clipboard.writeText(deviceIds);
        break;
      }
      default:
        break;
    }
  };

  return (
    <Paper square elevation={3}>
      <BottomNavigation value={currentSelection()} onChange={handleSelection} showLabels>
        <BottomNavigationAction
          label={t('homeTitle')}
          icon={(
            <Badge color="error" variant="dot" overlap="circular" invisible={socket !== false}>
              <HomeIcon />
            </Badge>
          )}
          value="home"
        />
        {!disableReports && (
          <BottomNavigationAction
            label={t('reportTitle')}
            icon={<DescriptionIcon />}
            value="reports"
            disabled={!selectedDeviceId}
            className={selectedDeviceId ? classes.enabled : classes.disabled}
          />
        )}
        <BottomNavigationAction
          label={t('toolsTitle')} // Label for tools
          icon={<BuildIcon />} // Icon for tools
          value="tools"
        />
        <Menu anchorEl={toolsAnchorEl} open={Boolean(toolsAnchorEl)} onClose={() => setToolsAnchorEl(null)}>
          <MenuItem onClick={() => handleSelection(null, 'tools/testDevice')}>{t('sharedTestDevice')}</MenuItem>
          <MenuItem onClick={() => handleSelection(null, 'tools/findServices')}>{t('toolsFindService')}</MenuItem>
          {admin && (
            <MenuItem onClick={() => handleSelection(null, 'tools/copyDeviceIds')}>{t('toolsCopyDevices')}</MenuItem>
          )}
        </Menu>
        {readonly ? (
          <BottomNavigationAction label={t('loginLogout')} icon={<ExitToAppIcon />} value="logout" />
        ) : (
          <BottomNavigationAction label={t('profileTitle')} icon={<PersonIcon />} value="account" />
        )}
      </BottomNavigation>
      <Menu anchorEl={profileAnchorEl} open={Boolean(profileAnchorEl)} onClose={() => setProfileAnchorEl(null)}>
        {!recovery && (
          <MenuItem onClick={handleSettings}>
            <Typography color="textPrimary">{t('settingsTitle')}</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <Typography color="error">{t('loginLogout')}</Typography>
        </MenuItem>
      </Menu>
    </Paper>
  );
};

export default BottomMenu;
