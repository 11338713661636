import React from 'react';
import CopyableText from './CopyableText';

export const VehicleData = (device) => {
  const fields = [
    {
      id: 'vehicleVIN',
      value: device.vin ? <CopyableText text={device.vin} /> : '',
      raw: !!device.vin,
    },
    { id: 'vehicleYear', value: (device.vehicleYear || '') },
    { id: 'vehicleMake', value: (device.vehicleMake || '') },
    { id: 'vehicleModel', value: (device.vehicleModel || '') },
    { id: 'vehiclePlate', value: (device.vehiclePlate || '') },
    { id: 'vehicleState', value: (device.vehicleState || '') },
  ];
  return fields;
};

export const ContactData = (device) => {
  const fields = [
    { id: 'deviceContact', value: (device.contact || '') },
    { id: 'sharedPhone', value: (device.phone || '') },
    { id: 'sharedAddress', value: (device.address || '') },
    { id: 'sharedCity', value: (device.city || '') },
    { id: 'sharedState', value: (device.state || '') },
    { id: 'sharedZip', value: (device.zip || '') },
  ];
  return fields;
};
