import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Container,
  FormControl, OutlinedInput, Button, Checkbox, FormControlLabel, FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import { useCatch } from '../reactHelper';
import { useAdministrator } from '../common/util/permissions';
import { postJsonAPI } from '../common/util/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  recipient: {
    marginBottom: theme.spacing(2),
  },
}));

const BulkTransferPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();
  const admin = useAdministrator();
  const userEmail = useSelector((state) => state.session.user.email);
  const deviceStore = useSelector((state) => state.devices.items);

  const [devices, setDevices] = useState('');
  const [recipientEmail, setRecipientEmail] = useState(localStorage.getItem('lastTransferEmail') || '');
  const [notify, setNotify] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [actionResult, setActionResult] = useState(null);

  // Function to find a device ID by unique ID
  const findDeviceIdByUniqueId = (uniqueId) => {
    const found = Object.values(deviceStore).find(
      (device) => device.uniqueId === uniqueId,
    );
    const result = found ? found.id : null;
    return result;
  };

  const handleSave = useCatch(async () => {
    if (!recipientEmail) {
      setActionResult('Recipient email is required');
      return;
    }

    if (recipientEmail) {
      localStorage.setItem('lastTransferEmail', recipientEmail);
    }

    setProcessing(true);
    setActionResult(null);

    const deviceUniqueIds = devices.split('\n');
    let successCount = 0;
    let failCount = 0;
    let notFoundCount = 0;

    // Process each unique ID from the input
    await Promise.all(deviceUniqueIds.map(async (uniqueId) => {
      if (uniqueId.trim()) {
        const trimmedUniqueId = uniqueId.trim();
        const deviceId = findDeviceIdByUniqueId(trimmedUniqueId);

        if (deviceId) {
          try {
            await postJsonAPI(
              'orders',
              {
                uniqueId: Date.now(),
                description: 'Device Transfer',
                fromAddress: userEmail,
                toAddress: recipientEmail,
                attributes: {
                  deviceId,
                  notify,
                },
              },
              false,
              null, // Don't use built-in message
              null, // Don't set actionResult internally
            );
            successCount += 1;
          } catch (error) {
            failCount += 1;
          }
        } else {
          notFoundCount += 1;
        }
      }
    }));

    // Generate appropriate result message
    if (successCount > 0) {
      let message = `Successfully transferred ${successCount} devices.`;
      if (failCount > 0) {
        message += ` Failed to transfer ${failCount} devices.`;
      }
      if (notFoundCount > 0) {
        message += ` ${notFoundCount} device(s) not found.`;
      }
      setActionResult(message);
    } else if (failCount > 0 || notFoundCount > 0) {
      let message = 'Failed to transfer any devices.';
      if (notFoundCount > 0) {
        message += ` ${notFoundCount} device(s) not found.`;
      }
      setActionResult(message);
    } else {
      setActionResult('No valid device IDs to transfer');
    }

    setProcessing(false);
  });

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedBulkTransfer']}>
      <Container maxWidth="xs" className={classes.container}>
        {admin && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedBulkTransfer')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormControl className={classes.recipient}>
                  <Typography variant="body2" gutterBottom>
                    {t('sharedRecipientEmail')}
                  </Typography>
                  <OutlinedInput
                    placeholder="email@example.com"
                    value={recipientEmail}
                    onChange={(e) => setRecipientEmail(e.target.value)}
                    fullWidth
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={notify}
                        onChange={(e) => setNotify(e.target.checked)}
                        color="primary"
                      />
                    )}
                    label={t('sharedNotifyUser') || 'Notify recipient'}
                  />
                </FormControl>
                <FormControl>
                  <Typography variant="body2" gutterBottom>
                    {t('deviceIdentifiers')}
                  </Typography>
                  <OutlinedInput
                    multiline
                    rows={10}
                    placeholder={t('sharedEnterDeviceIds') || 'Enter device IDs, one per line'}
                    value={devices}
                    onChange={(event) => setDevices(event.target.value)}
                  />
                  {actionResult && (
                    <FormHelperText error>{actionResult}</FormHelperText>
                  )}
                </FormControl>
              </AccordionDetails>
            </Accordion>
            <div className={classes.buttons}>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t('sharedCancel')}
              </Button>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={handleSave}
                disabled={processing}
              >
                {processing ? t('sharedProcessing') : t('sharedTransfer')}
              </Button>
            </div>
          </>
        )}
      </Container>
    </PageLayout>
  );
};

export default BulkTransferPage;
